import { useContext, useEffect, useState } from "react";
import SelectRow from "./SelectRow";
import axios from "axios";
import { LangContext } from "../../../App";

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import __server, { __countries } from "../../../Api";

export default function CountryInput ()
{
  const [options, setOptions] = useState([]);
  const { lang } = useContext(LangContext);

  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  useEffect(() => {
    fetchAPICountriesExpectMakeConstantOnesFirst();
  }, []);

  const fetchAPICountriesExpectMakeConstantOnesFirst = function ()
  {
    axios.get(`${__server}/front/pages/countries`).then((resp) => {
      const baseCountries = resp.data.map((code) => countryCodeToOption(code));

      const baseWithoutTop = baseCountries.filter(c => !__countries.includes(c.value));
      const topCountries = __countries.map(c => countryCodeToOption(c));

      const sortedCountries = topCountries.concat(baseWithoutTop);

      setOptions(sortedCountries);
    })
  }

  const countryCodeToOption = function (countryCode)
  {
    return {
      value: countryCode,
      label: countries.getName(countryCode, lang)
    }
  }


  return (
    <SelectRow
      name="country" 
      options={options} 
      label={MenuTranslations[lang].slCountry} 
      placeholder={MenuTranslations[lang].spCountry} 
      isClearable={true}
      isSearchable={true}
    />
  )
}
