import { useContext } from "react";
import { OfferParamsContext } from "./OfertaPage";
import styled from "styled-components";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import Select from 'react-select'

const Wrapper = styled.div`
    margin-top: 20px;
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
    font-size: 20px;
    color: #133b51;

    display: flex;
    align-items: center;
    gap: 25px;
`

export default function BundleHeads ()
{
    const { offerParams, setOfferParams } = useContext(OfferParamsContext);
    const { lang } = useContext(LangContext);

    const options = (new Array(12 - 1)).fill(0).map((n, i) => {
        return {
            value: i + 2,
            label: i + 2
        }
    });

    const onChange = function (e)
    {
        const url = new URL(window.location.href);
        url.searchParams.set('heads', e.value);
        window.history.pushState({}, '', url.href);
        setOfferParams({...offerParams, heads: e.value});
    }

    return (
        <Wrapper>
            {Translations[lang].heads}
            <Select 
                noOptionsMessage={() => "Nic nie znaleziono"}
                isClearable={false} 

                options={options}
                value={options.filter(n => n.value == offerParams.heads)}
                onChange={onChange}

                styles = {{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    color: '#133b51',
                    borderColor: '#32C8D2 !important',
                    fontFamily: 'Gilroy-Light',
                    boxShadow: state.isFocused ? '0 0 0 1px #3792ca' : 'none'
                }),
                indicatorSeparator: (baseStyles, state) => ({
                    ...baseStyles,
                    display: 'none'
                }),
                menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: 'Gilroy-Light',
                    color: '#133b51',
                    zIndex: 999
                }),
                menu: provided => ({ ...provided, zIndex: 9999 })
                }}
            />
        </Wrapper>
    )
}