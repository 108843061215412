import styled from "styled-components"
import { Title } from "./CityPage"
import { Translations } from "./Lang"
import { useContext, useEffect } from "react"
import { LangContext } from "../../../App"

const Wrapper = styled.div`
    box-sizing: border-box;
    padding-top: 50px;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media (max-width: 767px) {
        padding: 30px 15px 0px 15px;
        flex-direction: column;
    }
`

const Content = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    margin-top: 20px;
    width: 925px;

    h2 {
        font-family: 'Gilroy-ExtraBold';
        font-size: 24px;
        font-weight: 400;
        margin-block-start: 10px;
        margin-block-end: 0px;
    }

    p {
        margin-block-start: 10px;
        margin-block-end: 0px;
    }

    img {
        width: 100%;
        height: 370px;
        object-fit: cover;
        border-radius: 10px;
    }

    @media (max-width: 767px) {
        width: 100%;
        font-size: 14px;

        h2 {
            font-size: 18px;
        }
    }
`

const Box = styled.div`
    width: 300px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 15px;
    height: fit-content;

    @media (max-width: 767px) {
        width: 100%;
    }
`


const BoxTitle = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 22px;
    font-weight: 400;
    color: #133b51;
`

const List = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const Element = styled.div`
    display: flex;
    gap: 9px;
    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
    color: #133b51;

    > img {
        width: 15px;
    }

    a {
        text-decoration: none;
        color: #133b51;
    }
`


export default function Route ({ content })
{
    const { lang } = useContext(LangContext);

    /**
     * Magiczny z dupy box po prawej
     */

    useEffect(() => {

        const stickTop = 40;
        const infoBoxTrigger = document.querySelector('.info-box-trigger');
        const infoBoxBottomTrigger = document.querySelector('.info-box-bottom-trigger');
        const infoBox = document.querySelector('.info-box');


        window.onscroll = function (e) {
            if (window.scrollY + 200 + stickTop > infoBoxBottomTrigger.offsetTop) {
                infoBox.style = `position: static`;
                infoBoxTrigger.style = `display: flex; align-items: flex-end`
            } else if (window.scrollY > infoBoxTrigger.offsetTop - stickTop) {
                infoBox.style = `position: fixed; top: ${stickTop}px; right: calc((100vw - 1320px) / 2 - 10px)`;
            } else {
                infoBox.style = `position: static`;
                infoBoxTrigger.style = `display: flex; align-items: flex-start`
            }
        }

    }, []);

    return (
        <Wrapper id="trasa">
            <div>
                <Title>
                    {Translations[lang].route}
                </Title>
                <Content dangerouslySetInnerHTML={{ __html: content }} />
                <div class="info-box-bottom-trigger"></div>
            </div>
            <div className="info-box-trigger">
                <Box className="info-box">
                    <BoxTitle>
                        {Translations[lang].areYouInterested}
                    </BoxTitle>
                    <List>
                        <Element>
                            <img src="/ico/phone-blue.svg" />
                            <a href="tel:+48668678019">+48 668 678 019, </a> 
                            <a href="tel:+48887234362">+48 887 234 362</a>
                        </Element>
                        <Element>
                            <img src="/ico/envelope-blue.svg" />
                            <a href="mailto:biuro@forsail.pl">biuro@forsail.pl</a>
                        </Element>
                        <Element>
                            <img src="/ico/pointer3.svg" />
                            ul. Ciołka 13, 01-445 Warszawa
                        </Element>
                    </List>
                </Box>
            </div>
        </Wrapper>
    )
}