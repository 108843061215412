import { useContext, useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import MyData from "./MyData/MyData";
import { Booking } from "./Booking/Booking";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: 50px;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 30px;
  }
`

const Tabs = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 50px;
`

const Tab = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  cursor: pointer;

  ${params => params.$isActive && `
    font-family: 'Gilroy-ExtraBold';
    text-underline-offset: 10px;
    text-decoration: underline;
    cursor: default;
  `}
`

export default function ProfilePage ()
{
  const { lang } = useContext(LangContext);

  const tabs = [
    {
      name: Translations[lang].myBookings,
      component: ({profile}) => <Booking />
    },
    {
      name: Translations[lang].myData,
      component: ({profile}) => <MyData profile={profile}/>
    }
  ]

  const [profile, setProfile] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabs.at(0));

  useEffect(() => {

    const bearer = localStorage.getItem('bearer_token');

    if (bearer == null) {
      window.location.href = '/logowanie';
      return () => {};
    }

    axios.get(`${__server}/front/profile`).then((resp) => {
      console.log(resp);
      setProfile(resp.data);
    }).catch((err) => {
      localStorage.removeItem('bearer_token');
      window.location.href = '/logowanie';
    })
  }, []);


  return (
    <>
      <LiteMenu />
      <Wrapper>
        <Tabs>
          {tabs.map((tab, key) => (
            <Tab key={key} onClick={(e) => setCurrentTab(tab)} $isActive={tab.name === currentTab.name}>
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        {<currentTab.component profile={profile} />}
      </Wrapper>
      <Footer />
    </>
  )
}