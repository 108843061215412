
import { styled } from 'styled-components';
import { SectionTitle } from './Yacht';
import { Translations } from './Lang';
import { useContext, useEffect, useState } from 'react';
import { LangContext } from '../../../App';
import axios from 'axios';
import __server from '../../../Api';
import Callendar from './Callendar';
import { ScaleLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';

const Wrapper = styled.div`
  margin-top: 40px;
`

const Box = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  
  @media (max-width: 767px) {
    gap: 0px;
  }
`

const ArrowWrapper = styled.img`
  cursor: pointer;
  width: 10px;
  transform: rotate(${params => params.$rot}deg);
`

const Legend = styled.div`
  margin-top: 38px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 16px;
  color: #133b51;
  box-sizing: border-box;
  padding-left: 94px;

  @media (max-width: 767px) {
    padding: 0px;
    font-size: 14px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

const Color = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: 1px solid ${params => params.$border};
  background-color: ${params => params.$bg};
`

const LoadingWrapper = styled.div`
  margin-top: 50px;
  min-height: 361px;
  width: 100%;
  display: flex;
  justify-content: center;
`


const DisabledOnMobile = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export default function Callendars ({ id })
{
  const { lang } = useContext(LangContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [current, setCurrent] = useState(null);
  const [next, setNext] = useState(null);

  const [date, setDate] = useState({
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1
  });

  useEffect(() => {
    if (id != null) {
      setCurrent(null);
      setNext(null);
      axios.get(`${__server}/front/availability/${id}?month=${date.month}&year=${date.year}`).then((resp) => {
        if (resp.data.current.availability.length === 0 || resp.data.next.availability.length === 0) {
          console.error(`Yacht ${id} has no valid callendars`);
          setIsDisabled(true);
          return;
        }
        setCurrent(resp.data.current);
        setNext(resp.data.next);
      }).catch((err) => {
        console.error(err);
      })
    }
  }, [id, date])

  const Arrow = function ({ isRight })
  {
    const onClick = function (e)
    {
      const newDate = {
        month: date.month + (isRight ? 1 : -1),
        year: date.year
      };

      if (newDate.month === 0) {
        newDate.year -= 1;
        newDate.month = 12;
      }

      if (newDate.month === 13) {
        newDate.year += 1;
        newDate.month = 1;
      }

      setDate(newDate);
    }

    return (
      <ArrowWrapper 
        src="/ico/arrow-callendar.svg"
        $rot={isRight ? 180 : 0} 
        onClick={onClick}
      />
    )
  }

  const RenderCallendars = function ()
  {
    if (current == null || next == null) {
      return (
        <Box>
          <Skeleton width={470} height={361} />
          <Skeleton width={470} height={361} />
        </Box>
      );
    }

    return (
      <Box>
        <Arrow isRight={false} />
        <Callendar {...current} />
        <DisabledOnMobile>
          <Callendar {...next} />
        </DisabledOnMobile>
        <Arrow isRight={true} />
      </Box>
    )
  }

  if (id == null) {
    return (
      <Box>
        <Skeleton width={470} height={490} />
        <Skeleton width={470} height={490} />
      </Box>
    )
  }

  if (isDisabled) {
    return <></>;
  }

  return (
    <Wrapper>
      <SectionTitle>
        {Translations[lang].callendar.title}
      </SectionTitle>
      <RenderCallendars/>
      <Legend>
        <Item>
          <Color $bg='#ffffff' $border='#32c8d2' /> {Translations[lang].callendar.available}
        </Item>
        <Item>
          <Color $bg='#73c4c9' $border='#32c8d2' /> {Translations[lang].callendar.reservation}
        </Item>
        <Item>
          <Color $bg='#fa9e9e' $border='#fa9e9e' /> {Translations[lang].callendar.unavailable}
        </Item>
      </Legend>
    </Wrapper>
  )

}