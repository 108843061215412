
export const Translations = {
  pl: {
    backButton: "Wróć do katalogu",
    top: {
      whichPatent: "JAKI PATENT?",
      reservationTerms: "WARUNKI REZERWACJI",
      priceTag: "Wartość kaucji:",
      calendarButton: "KALENDARZ DOSTĘPNOŚCI",
      from: "od",
      noDeposit: "BRAK KAUCJI"
    },
    details: {
      title: "PODSTAWOWE INFORMACJE:",
      model: "Jacht",
      year: "Rocznik",
      country: "Kraj",
      region: "Region",
      port: "Port",
      companyName: "Armator",
      berths: "Liczba koi",
      cabins: "Kabiny",
      wc: "WC",
      length: "Długość (m)",
      beam: "Szerokość (m)",
      draught: "Zanurzenie (m)",
      waterCapacity: "Zbiornik wody (l)",
      fuelCapacity: "Zbiornik paliwa (l)",
      engine: "Silnik",
    },
    equipment: {
      title: "WYPOSAŻENIE:"
    },
    goodToKnow: {
      title: "WARTO WIEDZIEĆ",
      descr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    },
    baseDetails: {
      title: "SZCZEGÓŁY MARINY"
    },
    callendar: {
      title: "KALENDARZ DOSTĘPNOŚCI",
      months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ],
      days: [
        "PO", "WT", "ŚR", "CZ", "PT", "SO", "NI"
      ],
      available: 'Dostępny',
      reservation: 'Zarezerwowany',
      unavailable: 'Niedostępny'
    },
    popup: {
      terms: {
        title: "WARUNKI REZERWACJI",
        subtitle: "Jakie są ogólne warunki rezerwacji jachtów?",
        list: [
          "System rezerwacji obsługuje wyłącznie firmy czarterowe wybrane przez Forsail",
          "System zawiera fotografie oraz opisy jachtów zaciągane z globalnych systemów rezerwacyjnych. Forsail nie ma żadnego wpływu na ich treść.",
          "Klikając przycisk REZERWUJ klient automatycznie dokonuje rezerwacji wybranego jachtu w określonym przez siebie terminie. W najkrótszym możliwym czasie Forsail zweryfikuje poprawność dokonanej rezerwacji i prześle klientowi umowę czarteru. Określa ona szczegółowo warunki czarteru oraz terminy płatności.",
          "Aby potwierdzić rezerwację klient musi dokonać płatności w określonym umową terminie i odesłać do nas podpisaną umowę.",
          "Rezerwacja ważna jest od 1 do 5 dni w zależności od terminu czarteru. W tym okresie klientowi przysługuje prawo do rezygnacji z rezerwacji.",
          "Klient w ciągu jednego dnia może dokonać maksymalnie dwóch rezerwacji korzystając z systemu automatycznej rezerwacji jachtu.",
          "Forsail podczas zawierania umowy o czarter jachtu, pełni jedynie rolę pośrednika pomiędzy klientem a armatorem.",
          "Dokonując rezerwacji jachtu na stronie www.forsail.pl klient akceptuje niniejsze warunki.",
        ]
      },
      patents: {
        title: "JAKI PATENT?",
        subtitle: "Jakie uprawnienia są wymagane, aby wynająć jacht?",
        items: [
          {
            title: "Chorwacja",
            list: [
              "patent żeglarski minimum sternika jachtowego, jachtowego sternika morskiego lub dla jachtów motorowych patent motorowodny sternika motorowodnego, starszego sternika motorowodnego ORAZ świadectwo radiooperatora (SRC) LUB",
              "chorwacki patent Voditelj Brodice"
            ]
          },
          {
            title: "Grecja",
            list: [
              "patent żeglarski minimum sternika jachtowego, jachtowego sternika morskiego lub dla jachtów motorowych patent motorowodny sternika motorowodnego, starszego sternika motorowodnego ORAZ",
              "w załodze musi znajdować się druga osoba z udokumentowanym doświadczeniem żeglarskim tzw. co-skipper."
            ]
          },
          {
            title: "Włochy",
            list: [
              "patent żeglarski minimum sternika jachtowego, jachtowego sternika morskiego lub dla jachtów motorowych patent motorowodny sternika motorowodnego, starszego sternika motorowodnego oraz świadectwo radiooperatora (SRC)"
            ]
          },
          {
            title: "Pozostałe akweny",
            list: [
              "patent żeglarski minimum sternika jachtowego, jachtowego sternika morskiego lub dla jachtów motorowych patent motorowodny sternika motorowodnego, starszego sternika motorowodnego"
            ]
          },
        ]
      }
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;