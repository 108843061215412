import styled from "styled-components"
import { Title } from "./CityPage"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"

const Wrapper = styled.div`
    width: 925px;
    box-sizing: border-box;
    padding-top: 50px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 50px 15px 0px 15px;
    }
`

const Grid = styled.div`
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`

const Box = styled.div`
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`

const Icon = styled.img`
    height: 50px;
`

const BoxTitle = styled.div`
    margin-top: 30px;
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
    color: #133b51;
    font-size: 24px;
`

const BoxSubtitle = styled.div`
    margin-top: 10px;
    font-family: 'Gilroy-Light';
    font-weight: 400;
    color: #133b51;
    font-size: 20px;
`

export default function Deadlines ({ transport, deadline })
{
    const { lang } = useContext(LangContext);

    function Boxed ({ icon, title, subtitle })
    {
        return (
            <Box>
                <Icon src={icon} />
                <BoxTitle>
                    {title}
                </BoxTitle>
                <BoxSubtitle>
                    {subtitle}
                </BoxSubtitle>
            </Box>
        )
    }

    return (
        <Wrapper id="transport">
            <Title>
                {Translations[lang].deadlines}
            </Title>
            <Grid>
                <Boxed icon="/ico/plane.svg" title={Translations[lang].transport} subtitle={transport} />
                <Boxed icon="/ico/calendar-blue.svg" title={Translations[lang].recommendedDeadline} subtitle={deadline} />
            </Grid>
        </Wrapper>
    )
}