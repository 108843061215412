import { m } from "framer-motion";

export const Translations = {
    pl: {
        myBookings: "Moje rezerwacje",
        myData: "Moje dane",
        
        myDataPage: {
            personalData: "Dane osobiste",
            name: "Imię",
            surname: "Nazwisko",
            serial: "Seria i numer dowodu",
            companyData: "Dane firmowe",
            companyName: "Nazwa firmy (opcjonalne)",
            nip: "NIP (opcjonalne)",
            address: "Adres",
            street: "Nazwa ulicy",
            house: "Numer budynku",
            flat: "Numer lokalu (opcjonalne)",
            country: "Kraj",
            postCode: "Kod pocztowy",
            city: "Miasto",
            contactData: "Dane kontaktowe",
            mail: "Adres e-mail",
            tel: "Numer telefonu",
            save: "ZATWIERDŹ"
        },

        myBookingPage: {
            list: "Lista rezerwacji",
            id: "Numer",
            kind: "Typ jachtu",
            start: "Port / Data rozpoczęcia",
            end: "Port / Data zakończenia",
            status: "Status",
            price: "Cena",
            options: "Operacje",
            statuses: {
                "-1": "Odrzucono",
                0: "W trakcie weryfikacji",
                1: "Wymaga podpisania dokumentów",
                2: "W trakcie weryfikacji podpisu",
                3: "Wymaga płatności",
                4: "Opłacone"
            }
        }
    }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
