import { useContext, useEffect, useState } from "react"
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import Footer from "../../Components/Footer/Footer";
import styled from "styled-components";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import axios from "axios";
import __server from "../../Api";
import { formatNumberByCountry } from "../../utils/number-formatter";
import { FormProvider } from "react-hook-form";

const Wrapper = styled.form`
    width: 1320px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 30px;
`

const Column = styled.div`
    width: 50%;
`

const Bar = styled.div`
    width: 1px;
    background-color: #133b51;
`

const Title = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 32px;
    font-weight: 400;
    color: #133b51;
`

const Rows = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`

const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 18px;
`

const Group = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Label = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
`


const TosLabel = styled.label`
    font-family: 'Gilroy-Light';
    font-size: 14px;
    font-weight: 400;
    color: #133b51;
    width: 100%;
    text-align: left;
`


/** Inputy */

const TextBox = styled.input.attrs({ type: "text" })`
    width: 100%;
    outline: none;
    border: 1px solid #133b51;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 22px 10px;
    font-family: 'Gilroy-Light';
    font-size: 14px;
    font-weight: 400;
    color: #133b51;
    background-color: transparent;

    &:hover {
        border-color: hsl(0, 0%, 70%);
    }
    &:focus {
        border-color: #2684ff;
        box-shadow: 0px 0px 0px 1px #2684ff;
    }
    &::placeholder {
        color: #878b93;
    }
    &:disabled {
        background-color: hsl(0, 0%, 94%);
    }
    &:disabled:hover {
        border: 1px solid #133b51;
    }
    
`

const CheckBox = styled.input.attrs({ type: "checkbox" })`
    accent-color: #133b51;
    transform: scale(1.5);
`

const Submit = styled.button`
    margin-top: 40px;
    align-self: flex-end;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #133b51;
    box-sizing: border-box;
    font-family: 'Gilroy-ExtraBold';
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 14px 32px;
`


const Summary = styled.div`
    background-color: rgba(50, 200, 210, 0.1);
    border-radius: 10px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    box-sizing: border-box;
    padding: 30px;
`

const SummaryTitle = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;

    > b {
        font-family: 'Gilroy-ExtraBold';
    }
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
`

const ElementWrapper = styled.div`
    display: flex;
    gap: 5px;
    font-family: 'Gilroy-Light';
    font-size: 14px;
    font-weight: 400;
    color: #133b51;
    align-items: center;

    img {
        width: 20px;
        translate: 0px -2px;
    }

    b {
        font-family: 'Gilroy-ExtraBold';
    }
`


export default function BookingPage ()
{
    const { lang } = useContext(LangContext);

    const [formData, setFormData] = useState({
        kind: '',
        year: '',
        cabins: '',
        startBase: '',
        startDate: '',
        endBase: '',
        endDate: '',
        totalPrice: '',
        securityDeposit: '',
        obligatoryExtrasPrice: '',
        bundles: {
            bundle: "",
            extrasToggled: [],
            price: 0
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    /**
     * Wymaga sesji oraz localStorage.forsail_booking
     */
    useEffect(() => {

        const form = localStorage.getItem("forsail_booking");

        /** Nie ma danych oferty */
        if (!form) {
            window.location.href = '/';
            return () => {};
        }

        axios.get(`${__server}/front/profile`).then((resp) => {
            /** Łączymy dane z profulu, z danymi z localStorage */
            setFormData({...JSON.parse(form), ...resp.data});
        }).catch((err) => {
            /** Nie ma sesji */
            window.location.href = `/logowanie?referer=rezerwacja`;
            return () => {};
        })

    }, []);

    const onSubmit = function (e)
    {
        e.preventDefault();
        axios.post(`${__server}/front/booking`, formData).then((resp) => {
            console.log(resp);
            localStorage.removeItem("forsail_booking");
            window.location.href = '/profil';
        }).catch((err) => {
            console.error(err);
        })
    }

    const Element = function ({ text, price })
    {
        return (
            <ElementWrapper>
                <img src="/ico/circle.svg" />
                <div>
                    {text} <b>({formatNumberByCountry(price, lang, 2)} EUR)</b>
                </div>
            </ElementWrapper>
        )
    }

    return (
        <>
            <LiteMenu />
            <Wrapper onSubmit={onSubmit}>
                <Column>
                    <Title>
                        {Translations[lang].bookingInfo}
                    </Title>
                    <Rows>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].kind}
                                </Label>
                                <TextBox name="kind" value={formData.kind} onChange={handleChange} disabled={true}/>
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].year}
                                </Label>
                                <TextBox name="year" value={formData.year} onChange={handleChange} disabled={true}/>
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].cabins}
                                </Label>
                                <TextBox name="cabins" value={formData.cabins} onChange={handleChange} disabled={true}/>
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].heads}
                                </Label>
                                <TextBox name="heads" value={formData.heads} onChange={handleChange} disabled={true}/>
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].startBase}
                                </Label>
                                <TextBox name="startBase" value={formData.startBase} onChange={handleChange} disabled={true}/>
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].startDate}
                                </Label>
                                <TextBox name="startDate" value={formData.startDate} onChange={handleChange} disabled={true}/>
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].endBase}
                                </Label>
                                <TextBox name="endBase" value={formData.endBase} onChange={handleChange} disabled={true}/>
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].endDate}
                                </Label>
                                <TextBox name="endDate" value={formData.endDate} onChange={handleChange} disabled={true}/>
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].totalPrice}
                                </Label>
                                <TextBox name="totalPrice" value={`${formData.totalPrice} EUR`} onChange={handleChange} disabled={true}/>
                            </Group>
                        </Row>
                    </Rows>
                    <Summary>
                        <SummaryTitle>
                            {Translations[lang].obligatoryPayments}
                        </SummaryTitle>
                        <List>
                            <Element text={Translations[lang].deposit} price={formData.securityDeposit}/>
                            <Element text={Translations[lang].obligatoryPayments} price={formData.obligatoryExtrasPrice}/>
                            <Element text={`${Translations[lang].bundle}: ${formData.bundle}`} price={formData.bundles.price}/>
                        </List>
                        {formData.bundles.extrasToggled.some(extra => extra.toggled) && (
                            <>
                                <SummaryTitle>
                                    {Translations[lang].extraPayments}
                                </SummaryTitle>
                                <List>
                                    {formData.bundles.extrasToggled.filter(n => n.toggled).map((extra, key) => (
                                        <Element key={key} text={extra.name} price={extra.value} />
                                    ))}
                                </List>
                            </>
                        )}
                    </Summary>
                </Column>
                <Bar />
                <Column>
                    <Title>
                        {Translations[lang].personalData}
                    </Title>
                    <Rows>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].name}
                                </Label>
                                <TextBox name="name" value={formData.name} onChange={handleChange} required={true} maxlength={32}/>
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].surname}
                                </Label>
                                <TextBox name="surname" value={formData.surname} onChange={handleChange} required={true} maxlength={32}/>
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].companyName}
                                </Label>
                                <TextBox name="companyInfo1" value={formData.companyInfo1} onChange={handleChange} maxLength={64} />
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].nip}
                                </Label>
                                <TextBox name="companyInfo2" value={formData.companyInfo2} onChange={handleChange} maxLength={14} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].country}
                                </Label>
                                <TextBox name="country" value={formData.country} onChange={handleChange} required={true} maxLength={16} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].postCode}
                                </Label>
                                <TextBox name="post" value={formData.post} onChange={handleChange} required={true} maxLength={6} />
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].city}
                                </Label>
                                <TextBox name="city" value={formData.city} onChange={handleChange} required={true} maxLength={32} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].street}
                                </Label>
                                <TextBox name="street" value={formData.street} onChange={handleChange} required={true} maxLength={48} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].house}
                                </Label>
                                <TextBox name="house" value={formData.house} onChange={handleChange} required={true} maxLength={8} />
                            </Group>
                            <Group>
                                <Label>
                                    {Translations[lang].flat}
                                </Label>
                                <TextBox name="flat" value={formData.flat} onChange={handleChange} maxLength={8} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].mail}
                                </Label>
                                <TextBox name="mail" value={formData.mail} onChange={handleChange} required={true} maxLength={64} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].tel}
                                </Label>
                                <TextBox name="tel" value={formData.tel} onChange={handleChange} required={true} maxLength={12} />
                            </Group>
                        </Row>
                        <Row>
                            <Group>
                                <Label>
                                    {Translations[lang].serial}
                                </Label>
                                <TextBox name="serial" value={formData.serial} onChange={handleChange} required={true} maxLength={9} />
                            </Group>
                        </Row>
                        <Row>
                            <CheckBox id="tos-cbx" required={true} />
                            <TosLabel htmlFor="tos-cbx">
                                {Translations[lang].tos}
                            </TosLabel>
                        </Row>
                        <Row>
                            <Submit>
                                {Translations[lang].submit}
                            </Submit>
                        </Row>
                    </Rows>
                </Column>
            </Wrapper>
            <Footer />
        </>
    )
}