import { useContext } from "react";
import { Translations } from "./Lang";
import { SectionTitle } from "./Yacht";
import { LangContext } from "../../../App";
import styled from "styled-components";
import Column from "./Column";
import Skeleton from "react-loading-skeleton";

const Columns = styled.div`
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px;

  @media (max-width: 767px) {
    display: block;
  }

`

export default function Details ({ details })
{
  const { lang } = useContext(LangContext);

  if (details == null) {
    return (
      <Columns>
        <Skeleton width={303.33} height={253} />
        <Skeleton width={303.33} height={253} />
        <Skeleton width={303.33} height={253} />
      </Columns>
    )
  }

  if (details.length === 0) {
    return <></>
  }

  /**
   * WARN: Listę detali rodzielamy na 3x kolumnyny, niestety,
   *       na DESIGNIE wersji mobilnej, renderujemy jedna pod
   *       drugą a kolory są 2n... musimy je dzielić w miarę
   *       po równo ale parzyscie
   * @type {*[]}
   */
  const detailSections = [];
  const trueDetailsInSection = parseInt(Math.ceil(details.length / 3));

  const detailsInSection = trueDetailsInSection & 1
    ? trueDetailsInSection + 1
    : trueDetailsInSection;

  for (let i = 0; i < 3; i++) {
    detailSections.push(details.slice(
      i * detailsInSection, 
      i * detailsInSection + detailsInSection
    ));
  }

  return (
    <>
      <SectionTitle>
        {Translations[lang].details.title}
      </SectionTitle>
      <Columns>
        {detailSections.map((details, key) => (
          <Column details={details} key={key} />
        ))}
      </Columns>
    </>
  )
}