import { useContext, useEffect, useState } from "react";
import { Translations } from "../Lang";
import { Card, EditToggle, Grid, Heading, InputBox, Subtitle, Label, TextBox, SaveButton } from "./MyData"; 
import { LangContext } from "../../../App";
import axios from "axios";
import __server from "../../../Api";

export default function Address ({ profile, setIsLoading })
{
    const { lang } = useContext(LangContext);

    const [editable, setEditable] = useState(false);

    const [street, setStreet] = useState("");
    const [house, setHouse] = useState("");
    const [flat, setFlat] = useState("");
    const [post, setPost] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {
        if (profile != null) {
            setStreet(profile.street);
            setHouse(profile.house);
            setFlat(profile.flat);
            setPost(profile.post);
            setCity(profile.city);
            setCountry(profile.country);
        }
    }, [profile])


    const onSave = function (e)
    {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${__server}/front/profile`, {
            street: street,
            house: house,
            flat: flat,
            post: post,
            city: city,
            country: country
        }).then((resp) => {
        }).catch((err) => {
            // idk
        }).finally(() => {
            // idk
            setEditable(false);
            setIsLoading(false);
        })
    }

    return (
        <Card onSubmit={onSave}>
            <Heading>
                <Subtitle>
                    {Translations[lang].myDataPage.address}
                </Subtitle>
                <EditToggle src="/ico/edit.svg" onClick={(e) => setEditable(!editable)}/>
            </Heading>
            <Grid>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.street}
                    </Label>
                    <TextBox maxlength={64} disabled={!editable} value={street} onChange={(e) => setStreet(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.house}
                    </Label>
                    <TextBox maxlength={8} disabled={!editable} value={house} onChange={(e) => setHouse(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.flat}
                    </Label>
                    <TextBox maxlength={8} disabled={!editable} value={flat} onChange={(e) => setFlat(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.postCode}
                    </Label>
                    <TextBox required={true} maxlength={6} disabled={!editable} value={post} onChange={(e) => setPost(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.city}
                    </Label>
                    <TextBox required={true} maxlength={32} disabled={!editable} value={city} onChange={(e) => setCity(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.country}
                    </Label>
                    <TextBox required={true} maxlength={32} disabled={!editable} value={country} onChange={(e) => setCountry(e.target.value)}/>
                </InputBox>
            </Grid>
            {editable && <SaveButton>{Translations[lang].myDataPage.save}</SaveButton>}
        </Card>
    )
}