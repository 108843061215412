import styled from "styled-components";
import {useContext} from "react";
import {LangContext} from "../../../App";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

const LangSwitcher = styled.div`
  font-family: 'Manrope';
  font-weight: ${props => props.$active ? 700 : 400};
  font-size: 16px;
  color: ${props => props.$active == 'true' ? '#133b51' : '#fff'};
  cursor: pointer;
`
export default function MenuLangSwitcher ()
{
	const { lang, setLang } = useContext(LangContext);

	const langList = ['pl', 'en', 'de'];

	return (
		<Wrapper>
			{langList.map((ln, key) => (
				<LangSwitcher
					key={key}
					$active={lang == ln ? 'true' : 'false'}
					onClick={(e) => {
						setLang(ln);
						localStorage.setItem('forsail_lang', ln);
					}}
				>
					{ln.toUpperCase()}
				</LangSwitcher>
			))}
		</Wrapper>
	)
}