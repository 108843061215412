
export const Translations = {
    pl: {
        bookingInfo: "Informacje o rezerwacji",
        personalData: "Dane osobowe",
        kind: "Typ jachtu",
        year: "Rocznik jachtu",
        cabins: "Liczba koi",
        startBase: "Początkowy port",
        startDate: "Data rozpoczęcia",
        endBase: "Końcowy port",
        endDate: "Data zakończenia",
        totalPrice: "Cena charteru",
        name: "Imię",
        surname: "Nazwisko",
        serial: "Seria i numer dowodu",
        companyName: "Nazwa firmy (opcjonalne)",
        nip: "NIP (opcjonalne)",
        address: "Adres",
        street: "Nazwa ulicy",
        house: "Numer budynku",
        flat: "Numer lokalu (opcjonalne)",
        country: "Kraj",
        postCode: "Kod pocztowy",
        city: "Miasto",
        contactData: "Dane kontaktowe",
        mail: "Adres e-mail",
        tel: "Numer telefonu",
        submit: "WYŚLIJ ZGŁOSZENIE",
        tos: "Lorem ipsum dolor sit amet, consecteur, lux lucreat octo porta serpenta.",
        obligatoryPayments: "Opłaty obowiązkowe",
        administrativePayments: "Opłaty administracyjne",
        deposit: "Kaucja",
        bundle: "Pakiet charterowy",
        extraPayments: "Opłaty dodatkowe",
        heads: "Liczba osób"
    }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;