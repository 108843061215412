import styled from "styled-components";
import { useContext } from "react";
import Select from 'react-select'
import { OffersFilterContext } from "../OfertyPage";
import RowInput from "./RowInput";
import { __countires_with_bundles } from "../../../Api";


export default function SelectRow ({name, isClearable, options, isSearchable, placeholder, label, altInput = null})
{
  const { filters, setFilters } = useContext(OffersFilterContext);

  if (!options) {
    return <></>;
  }

  return (
    <RowInput
      label={label}
      htInput={<Select 
        options = {options}
        placeholder = {placeholder}
        noOptionsMessage={() => "Nic nie znaleziono"}

        isClearable={isClearable} 
        isSearchable={isSearchable}

        value={options.filter((opt) => opt.value === filters[name])}
        onChange={(e) => {
          const value = e !== null
            ? e.value
            : null;

          const newFilters = {...filters};
          newFilters[name] = value;

          /** Sory za SRP ale chuj by mnie strelił jak bym miał przepisać całe filtry od zera */
          if (name == "country" && !__countires_with_bundles.includes(value)) {
            newFilters.bundle = "standard";
          }
          setFilters(newFilters);
        }}

        styles = {{
          control: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '6px',
            color: '#133b51',
            borderColor: '#32C8D2 !important',
            fontFamily: 'Gilroy-Light',
            boxShadow: state.isFocused ? '0 0 0 1px #3792ca' : 'none'
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none'
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Gilroy-Light',
            color: '#133b51',
            zIndex: 999
          }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />}
    />
  )
}