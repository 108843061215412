import styled from "styled-components"
import { SectionTitle } from "../Yacht/View/Yacht"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import { __bundles } from "../../Api"
import BundleButton from "./BundleButton"
import BundleExtras from "./BundleExtras"
import { OfferParamsContext } from "./OfertaPage"
import Skeleton from "react-loading-skeleton"
import BundleHeads from "./BundleHeads"

const Wrapper = styled.div`
    margin-bottom: 25px;
`

const Bundles = styled.div`
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    box-sizing: border-box;
    padding-right: 50px;
`

const Subtitle = styled.div`
    margin-top: 20px;
    font-family: 'Gilroy-Light';
    font-weight: 400;
    font-size: 20px;
    color: #133b51;
    > span {
        font-family: 'Gilroy-ExtraBold';
    }
`

const List = styled.ul`
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: 'Gilroy-Light';
    padding-inline-start: 22px;
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    ::marker {
        color: #32c8d2;
        font-size: 26px;
    }
`

const Element = styled.li`

`

export default function BundleSection ({ bundles, extras, price, setPrice, extrasToggled, setExtrasToggled })
{
    const { offerParams } = useContext(OfferParamsContext);
    const { lang } = useContext(LangContext);

    const getBundleName = function ()
    {
        return __bundles[offerParams.bundle];
    }

    if (bundles == null || extras == null) {
        return <Skeleton width={980} height={300} style={{ marginBottom: 35 }} />;
        
    }

    /**
     * W krajach które nie mają bundli, nie wiem
     * cudownie nic nie robimy
     */
    if (bundles.length == 0 && extras.length == 0) {
        return <></>;
    }

    return (
        <Wrapper>
            <SectionTitle>
                {Translations[lang].selectedBundle}
            </SectionTitle>
            <Bundles>
                {Object.keys(__bundles).map((bundle, key) => (
                    <BundleButton bundle={bundle} bundleName={__bundles[bundle]} key={key} />
                ))}
            </Bundles>

            {bundles.length > 0 && (
                <>
                    <Subtitle>
                        <span> {Translations[lang].bundle} {getBundleName()} </span> {Translations[lang].includes}:
                    </Subtitle>

                    <List>
                        {bundles.map((bundle, key) => (
                            <Element key={key}>
                                {bundle.name}
                            </Element>
                        ))}
                    </List>
                </>
            )}

            {extras.length > 0 && <BundleExtras extras={extras} basePrice={price} setPrice={setPrice} extrasToggled={extrasToggled} setExtrasToggled={setExtrasToggled} />}

            <BundleHeads />
        </Wrapper>
    )
}