import { useParams } from "react-router-dom"
import CityMenu from "../../../Components/Menu/Factory/CityMenu";
import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import __server, { __gcloud } from "../../../Api";
import DiscoverThisPlace from "./DiscoverThisPlace";
import AdditionalMenuButInMiddleOfTheWebsite from "./AdditionalMenuButInMiddleOfTheWebsite";
import Route from "./Route";
import Footer from "../../../Components/Footer/Footer";
import Deadlines from "./Deadlines";
import Details from "./Details";
import ContactSection from "../../../Components/ContactSection/ContactSection";
import { LangContext } from "../../../App";

const Wrapper = styled.div`
    width: 1320px;
    margin: auto;

    @media (max-width: 767px) {
        width: 100%;
    }
`

export const Title = styled.div`
    font-family: 'Gilroy-Light';
    color: #133b51;
    font-size: 32px;
    font-weight: 400;

    > span {
        font-family: 'Gilroy-ExtraBold';
        color: #32c8d2;
    }

    @media (max-width: 767px) {
        font-size: 24px;
    }
`

export default function CityPage ({})
{
    const { lang } = useContext(LangContext);
    const { country, city } = useParams();

    const [data, setData] = useState({});

    useEffect(() => {
        axios.get(`${__server}/front/city/${city}?lang=${lang}`).then((resp) => {
            console.log(resp);
            setData(resp.data);
        }).catch((err) => {
            // window.location.href = '/';
        })
    }, []);


    // if (data == null) {
    //     return <></>
    // }

    return (
        <>
            <CityMenu
                title={data.name ?? ""}
                subtitle={data.subtitle ?? ""}
                backgroundImage={`${__gcloud}/${data.image ?? "placeholder.png"}`}
            />
            {Object.keys(data).length != 0 && (
                <>
                    <Wrapper>
                        <DiscoverThisPlace {...data} />
                    </Wrapper>
                    <AdditionalMenuButInMiddleOfTheWebsite />
                    <Wrapper>
                        <Route {...data} />
                        <Deadlines {...data} />
                        <Details {...data} />
                    </Wrapper>
                </>
            )}
            <ContactSection />
            <Footer />
        </>
    )
}