
import { useContext } from "react";
import { Link, Title } from "../Abstract/MenuComponents";
import { Translations } from "../Lang";
import { LangContext } from "../../../App";
import styled from "styled-components";
import MenuStripe from "../Components/MenuStripe";
import PageMenu from "./PageMenu";
import Menu from "../Abstract/Menu";

const Header = styled.header`
  width: 100%;
  background-color: #3792ca;
`

const MenuBarWrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 26px 0px;
`

const Logo = styled.img`
  width: 175px;
`

const LogoWrapper = styled.a`
  text-decoration: none;
`


const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 42px;
`

const UserButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #fff;
  border-radius: 5px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #32c8d2;
  box-sizing: border-box;
  padding: 14px 20px;
`

const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #fff;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  box-sizing: border-box;
  padding: 14px 35px;
`

const Buttons = styled.div`
  display: flex;
  gap: 17px;
`

const MobileWrapper = styled.div`
	display: none;
	@media (max-width: 767px) {
		display: block;
		width: 100%;
	}
`


export default function AuthMenu ()
{
  const { lang } = useContext(LangContext);

  return (
    <MobileWrapper>
      <Menu
        disabledMiddleSection={true}
        backgroundImage="/menu/bg-auth.png"
        gradient="none"
        navigation={<>
          <Link href="/incentive">Incentive</Link>
          <Link href="/academy">Academy</Link>
          <Link href="/camp">Camp</Link>
          <Link href="/yacht-charter">Jacht Charter</Link>
          <Link href="/o-nas">{Translations[lang].aboutUs}</Link>
          <Link href="/blog">{Translations[lang].blog}</Link>
        </>}
      />
    </MobileWrapper>
  )
}