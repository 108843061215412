import { m } from "framer-motion";

export const Translations = {
  pl: {
    status: "Status: Dostępny",
    length: "Długość",
    berths: "Liczba koi",
    cabins: "Kabiny",
    wc: "WC",
    equipmentTitle: "Wyposażenie",
    discount: "Promocja",
    requiredLicence: "Konieczność posiadania licencji",
    deposit: "Depozyt",
    found: "Znaleziono",
    variants: "wariantów",
    checkoutDetails: "ZOBACZ SZCZEGÓŁY",
    start: "od",
    days: "dni",
    ourPrice: "Nasza cena",
    extras: "opłaty",
    of: 'z',
    notFound: "Niestety nie znaleźliśmy jachtów w wybranym przez Ciebie terminie. Zmień kryteria wyszukiwania i spróbuj ponownie.",
    needHelp: "Potrzebujesz pomocy?  ",
    contactUs: "SKONTAKTUJ SIĘ",
    searchTitle: "Znajdź inny jacht",
    search: "WYSZUKAJ",

    kinds: [
      {
        value: "sailboat",
        label: "Jacht żaglowy"
      },
      {
        value: "motoryacht",
        label: "Jacht motorowy"
      },
      {
        value: "catamaran",
        label: "Katamaran"
      },
      {
        value: "powercatamaran",
        label: "Katamaran motorowy"
      },
    ],

    /** nie zmieniać value, dane z API */
    equipment: [
      {
        group: "Nawigacja",
        types: [
          {
            label: "Autopilot",
            value: 1
          },
          {
            label: "Chart plotter",
            value: 5
          },
        ]
      },
      {
        group: "Komfort",
        types: [
          {
            label: "Lodówka",
            value: 37
          },
          {
            label: "Ekspres do kawy",
            value: 25
          },
          {
            label: "Bimini",
            value: 7
          },
          {
            label: "Głośniki w kokpicie",
            value: 2
          },
          {
            label: "Poduszka kokpitu",
            value: 45
          },
          {
            label: "Ponton",
            value: 4
          },
        ]
      },
      {
        group: "Rozrywka",
        types: [
          {
            label: "Odtwarzacz CD",
            value: 14
          },
        ]
      },
      {
        group: "Żagle",
        types: [
          {
            label: "Lazy jack",
            value: 13
          },
          {
            label: "Lazy bag",
            value: 35
          },
        ]
      },
    ]
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
