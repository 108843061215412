import { useParams } from "react-router-dom"
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import __server from "../../Api";
import Footer from "../../Components/Footer/Footer";
import styled from "styled-components";
import Yacht from "../Yacht/View/Yacht";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import Top from "./Top";
import Section from "./Section";
import { ScaleLoader } from "react-spinners";
import BodyForYacht from "./BodyForYacht";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`

export const OfferParamsContext = createContext();

export default function OfertaPage ()
{
  const { id } = useParams();

  /**
   * Nie myślałem, że w życiu napiszę takie coś:
   */
  const [offerParams, setOfferParams] = useState(Object.fromEntries(Array.from(new URLSearchParams(window.location.search))));

  const [data, setData] = useState(null);

  useEffect(() => {

    const urlParams = new URLSearchParams(offerParams);

    if (data != null) {
      setData({...data, bundles: null, dateFrom: null, dateTo: null});
    }

    axios.get(`${__server}/front/search/${id}?${urlParams.toString()}`).then((resp) => {
      setData(resp.data);
      console.log(resp.data);

    }).catch((err) => {
      console.error(err);
    })
  }, [offerParams]);

  return (
    <OfferParamsContext.Provider value={{offerParams, setOfferParams}}>
      <LiteMenu />
      <Wrapper>
        <Yacht 
          yachtId={data?.yachtId} 
          extraProp={(
            <BodyForYacht data={data} />
          )} 
          overButtonProp={(
            <Top {...data} />
          )}
          disableBelowButtonsProp={true} 
        />
      </Wrapper>
      <Footer />
    </OfferParamsContext.Provider>
  )
}