import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";

export default function MenuStripeLink ({ href, label, icon })
{
    return (
        <IconBlock href={href}>
            <IconBlockImage src={icon} />
            <IconBlockLink>
                {label}
            </IconBlockLink>
        </IconBlock>
    )
}

export function MenuStripeLinkMail ({ mail })
{
    return <MenuStripeLink href={`mailto:${mail}`} label={mail} icon="/ico/envelope.svg" />
}

export function MenuStripeLinkPhone ({ tel })
{
    return <MenuStripeLink href={`tel:${tel.replaceAll(" ", "")}`} label={tel} icon="/ico/phone.svg" />
}