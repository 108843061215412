import { useContext } from "react"
import { LangContext } from "../../../App"
import { Translations } from "./Lang";
import { Title } from "./CityPage";
import styled from "styled-components";

const Wrapper = styled.div`
    box-sizing: border-box;
    padding-top: 50px;
    width: 925px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 50px 15px 0px 15px;
    }
`

const Content = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    margin-top: 20px;
    width: 925px;

    h2 {
        font-family: 'Gilroy-ExtraBold';
        font-size: 24px;
        font-weight: 400;
        margin-block-start: 10px;
        margin-block-end: 0px;
    }

    p {
        margin-block-start: 10px;
        margin-block-end: 0px;
    }

    a {
        color: #32c8d2;
    }

    ul {
        margin-block-start: 10px;
        margin-block-end: 0px;
        padding-inline-start: 20px;
    }


    @media (max-width: 767px) {
        width: 100%;
        font-size: 14px;

        h2 {
            font-size: 18px;
        }
    }
`

export default function Details ({ details })
{
    const { lang } = useContext(LangContext);

    return (
        <Wrapper id="szczegoly">
            <Title>
                {Translations[lang].details}
            </Title>
            <Content dangerouslySetInnerHTML={{ __html: details }} />
        </Wrapper>
    )
}