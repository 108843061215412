
export const Translations = {
    pl: {
        discoverThisPlace: <>Odkryj <span>to miejsce</span></>,
        routeLink: "Trasa rejsu",
        deadlineLink: "Terminy oraz transport",
        detailsLink: "Informacje szczegółowe",
        route: <>Trasa <span>Rejsu</span></>,
        areYouInterested: "Zainteresowała Cię ta oferta? Skontaktuj się!",
        deadlines: <>Terminy <span>oraz transport</span></>,
        transport: "Transport",
        recommendedDeadline: "Zalecany termin wyjazdu",
        details: <>Informacje <span>szczegółowe</span></>
    }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;