import styled from "styled-components"
import { __gcloud } from "../../Api"
import { useContext, useState } from "react"
import { LangContext } from "../../App"

import { motion } from 'framer-motion';

const Wrapper = styled.a`
  text-decoration: none;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.0) 76.36%, rgba(0, 0, 0, 0.3) 98.26%), url(${__gcloud}/${params => params.$bg});
  background-size: cover;
  background-position: center center;

`

const Gradient = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  height: 200px;
  align-items: flex-end;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 20px 35px;

  background-color: rgba(0, 0, 0, 0.0);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 767px) {
    padding: 12px 20px;
  }
`

const Label = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  font-weight: 400;
  color: #fff;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
  }
`

const Arrow = styled.div`
  background-image: url(/ico/arrow-destinations.svg);
  background-size: 49px;
  background-position: center right;
  background-repeat: no-repeat;
  width: 100%;
  height: 50px
`

export default function Country ({ id, name, namePL, image })
{
  const { lang } = useContext(LangContext);
  const [isHover, setIsHover] = useState(false);

  return (
    <Wrapper 
      $bg={image} href={`/nasze-destynacje/${namePL}`}
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}

      onTouchStart={(e) => setIsHover(true)}
      onTouchEnd={(e) => setIsHover(false)}
    >
      <Gradient>
        <Label>
          {name}
        </Label>
        <motion.div
          variants={{
            'hover': {
              width: 89,
              opacity: 1.0
            },
            'none': {
              width: 0,
              opacity: 0.0
            }
          }} 
          animate={isHover ? 'hover' : 'none'}
        >
          <Arrow />
        </motion.div>
      </Gradient>
    </Wrapper>
  )




}