import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import Model from "../../Pages/Models/Model";
import { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";

const Wrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	
	> a:nth-child(n+4) {
		display: none;
	}
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function VerticalModels ()
{
  const [list, setList] = useState(null);

  useEffect(() => {

    axios.get(`${__server}/front/promoted-model`).then((resp) => {
      setList(resp.data);
    }).catch((err) => {
      console.error(err);
    })

  }, []);

  if (list === null) {
    return (
      <Skeleton width={264} height={332} />
    );
  }

  return (
    <Wrapper>
      {list.map((item, key) => (
        <Model {...item.model} key={key} />
      ))}
    </Wrapper>
  )
}