import styled from "styled-components"
import { useContext } from "react"
import { LangContext, ThemeContext } from "../../../App"
import { Translations } from "../Lang"
import MenuLangSwitcher from "../Abstract/MenuLangSwitcher";

const MenuStripeWrapper = styled.div`
  width: 100%;
  background-color: ${params => params.$bg};

  @media (max-width: 767px) {
    display: none;
  }
`

const InnerBox = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
`

const LeftBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  font-family: 'Gilroy';
  font-weight: 300;
  color: #fff;
`

export const IconBlock = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`

export const IconBlockLink = styled.div`
  color: #fff;
`

export const IconBlockImage = styled.img`
  width: 16px;
  height: 16px;
`


const RightBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
`

const Subbrands = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`

const Subbrand = styled.a`
  text-decoration: none;
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
`

export default function MenuStripe ({ stripeItems })
{
  const {lang, setLang} = useContext(LangContext);
  const theme = useContext(ThemeContext);

  const subbrands = [
    "yacht-charter",
    "incentive",
    "academy",
    "camp"
  ]

  return (
    <MenuStripeWrapper $bg={theme.menuBarColor}>
      <InnerBox>
        <LeftBox>
          {/* <div>
            {Translations[lang].callUs}
          </div> */}
          {stripeItems}
        </LeftBox>
        <RightBox>
          <Subbrands>
            {subbrands.map((subbrand, key) => (
              <Subbrand href={`/${subbrand}`} key={key} >
                {Translations[lang].stripeBrands[key]}
              </Subbrand>
            ))}
          </Subbrands>
          <MenuLangSwitcher />
        </RightBox>
      </InnerBox>
    </MenuStripeWrapper>
  )
}