import { useContext } from "react";
import { Link } from "../Abstract/MenuComponents";
import { Translations } from "../Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  width: 100%;
  margin-top: 89px;
  text-align: center;
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;


  @media (max-width: 767px) {
    margin-top: 0px;
  }
`

const Subtitle = styled.div`
  width: 100%;
  margin-top: 5px;
  text-align: center;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #fff;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`

const Icon = styled.img`
  @media (max-width: 767px) {
    display: none;
  }
  margin-top: 140px;
  margin-bottom: 20px;
  width: 19px;
  cursor: pointer;
`

/**
 * Kurwa ja pierdole 18 menu
 */
export default function CityMenu ({ title, subtitle, backgroundImage })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundImage={backgroundImage}
      gradient="linear-gradient(180deg, rgba(217, 217, 217, 0.0) 10%, rgba(0, 0, 0, 0.72) 100%)"

      navigation={<>
        <Link href="/">Incentive</Link>
        <Link href="/">Academy</Link>
        <Link href="/">Camp</Link>
        <Link href="/">Jacht Charter</Link>
        <Link href="/o-nas">{Translations[lang].aboutUs}</Link>
        <Link href="/blog">{Translations[lang].blog}</Link>
      </>}

      middleSection={<></>}

      bottomSection={
        <Middle>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <a href="#odkryj"><Icon src="/ico/scroll.svg" /></a>
        </Middle>
      }

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 887 234 362" />
          <MenuStripeLinkMail mail="mailto:biuro@forsail.pl" />
          <MenuStripeSocials />
        </>
      }
    />
  )
}