/**
 * Strona główna
 */

import styled from "styled-components";
import DirectionsSection from "../../Components/About/DirectionsSection";
import CoursesSection from "../../Components/Courses/CoursesSection";
import Testimonials from "../../Components/Misc/Testimonials";
import BlogTop3 from "../../Components/Blog/BlogTop3";
import Newsletter from "../../Components/Newsletter/Newsletter";
import ContactSection from "../../Components/ContactSection/ContactSection";
import Footer from "../../Components/Footer/Footer";
import RecomendedCharters from "./RecomendedCharters";
import CompanyCruiseSection from "../../Components/CompanyCruiseSection/CompanyCruiseSection";
import Faq from "./Faq";
import Seasons from "./Seasons";
import MeetForsail from "./MeetForsail";
import HomePageMenu from "../../Components/Menu/Factory/HomePageMenu";
import Gallery from "../../Components/Gallery/Gallery";
import PromotedModelsSection from "../../Components/PromotedModels/PromotedModelsSection";
import Directions from "./Directions";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Divider = styled.div`
  width: 915px;
  height: 2px;
  margin: auto;
  margin-top: ${params => params.$top}px;
  background-color: ${params => params.$color};
`

const MagicUplift = styled.div`
  margin-top: -150px;
`

export default function HomePage ({})
{
  return (
    <>
      <HomePageMenu />
      <Wrapper>

        <RecomendedCharters />

        <CompanyCruiseSection />

        {/* <Divider $color="#94073a" $top={60} /> */}
        <CoursesSection top="50px" />

        {/* <Divider $color="#94073a" $top={40} /> */}

        {/* <Divider $color="#f2cf1c" $top={110} /> */}
        <Seasons />
        {/* <Divider $color="#f2cf1c" $top={40} /> */}

        <PromotedModelsSection top="50px" />

        <Directions />

        <Testimonials top="100px" />

        <MeetForsail />

        <Newsletter top="104px" />

        <BlogTop3 top="100px" />

        <Gallery />

        <Faq />

        <ContactSection top="66px" />

        <Footer />

      </Wrapper>
    </>
  )
}
