import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../../App"
import { Translations } from "./Lang"

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 22px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #32c8d2;
    gap: 115px;
    margin-top: 50px;

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 25px;
        margin-top: 40px;
    }
`

const Link = styled.a`
    font-family: 'Gilroy-ExtraBold';
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
`

export default function AdditionalMenuButInMiddleOfTheWebsite ({})
{
    const { lang } = useContext(LangContext)

    return (
        <Wrapper>
            <Link href="#trasa">{Translations[lang].routeLink}</Link>
            <Link href="#transport">{Translations[lang].deadlineLink}</Link>
            <Link href="#szczegoly">{Translations[lang].detailsLink}</Link>
        </Wrapper>
    )
}