import styled from "styled-components";
import { IconBlock, IconBlockImage } from "../Components/MenuStripe";
import MenuStripeLink from "./MenuStripeLink";

const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export default function MenuStripeSocials ()
{
    return (
        <Box>
            <MenuStripeLink href="" icon="/ico/instagram.svg" label="" />    
            <MenuStripeLink href="" icon="/ico/youtube.svg" label="" />    
            <MenuStripeLink href="" icon="/ico/facebook.svg" label="" />    
        </Box>
    )
}