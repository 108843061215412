import { useContext } from "react";
import styled from "styled-components";
import { OfferParamsContext } from "./OfertaPage";

const Button = styled.a`
    text-decoration: none;
    box-sizing: border-box;
    padding: 16px 0px;
    border-radius: 10px;
    border: 1px solid #32c8d2;
    background-color: ${params => params.$isSelected ? "#32c8d2" : "transparent"};
    color: ${params => params.$isSelected ? "#fff" : "#32c8d2"};
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
`

export default function BundleButton ({ bundle, bundleName })
{
    const { offerParams, setOfferParams } = useContext(OfferParamsContext);

    const isSelected = function ()
    {
        return offerParams.bundle === bundle;
    }

    const onClick = function (e)
    {
        const url = new URL(window.location.href);
        url.searchParams.set('bundle', bundle);
        window.history.pushState({}, '', url.href);
        setOfferParams({...offerParams, bundle: bundle});
    }

    return (
        <Button $isSelected={isSelected()} onClick={onClick}>{bundleName}</Button>
    )

}