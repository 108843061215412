import styled from "styled-components"
import { __gcloud } from "../../../Api"
import { Title } from "./CityPage"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../../App"

const Wrapper = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 20px;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 15px;
    }
`

const Image = styled.div`
    width: 400px;
    min-height: 550px;
    border-radius: 10px;
    background-image: url(${params => params.$bg});
    background-size: cover;
    background-position: center center;
    flex-shrink: 0;

    @media (max-width: 767px) {
        width: 100%;
        min-height: 360px;
        height: 360px;
    }
`

const Content = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    margin-top: 20px;

    @media (max-width: 767px) {
        margin-top: 10px;
        font-size: 14px;
    }
`

export default function DiscoverThisPlace ({ thumb, intro })
{
    const { lang } = useContext(LangContext);

    return (
        <Wrapper id="odkryj">
            <Image $bg={`${__gcloud}/${thumb}`} />
            <div>
                <Title>
                    {Translations[lang].discoverThisPlace}
                </Title>
                <Content dangerouslySetInnerHTML={{ __html: intro }} />
            </div>
        </Wrapper>
    )
}