import { useContext, useState } from "react"
import styled from "styled-components"
import { LangContext } from "../../../App"
import { Translations } from "../Lang"
import { Head } from "../../Subpages/Components/OfferPage/Table"
import PersonalData from "./PersonalData"
import CompanyData from "./CompanyData"
import Address from "./Address"
import ContactData from "./ContactData"

const Wrapper = styled.div`
    width: 1030px;

    @media (max-width: 767px) {
        width: 100%;
    }
`

const Title = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 32px;
    font-weight: 400;
    color: #133b51;

    @media (max-width: 767px) {
        font-size: 24px;
    }
`

const Cards = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const Card = styled.form`
    width: 1030px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 30px;

    @media (max-width: 767px) {
        width: 100%;
    }
`

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Subtitle = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 24px;
    font-weight: 400;
    color: #133b51;

    @media (max-width: 767px) {
        font-size: 18px;
    }
`

export const EditToggle = styled.img`
    width: 26px;
    cursor: pointer;
`

export const Grid = styled.div`
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 15px 0px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
`

export const Label = styled.div`
    margin-bottom: 10px;
    font-family: 'Gilroy-Light';
    font-weight: 400;
    font-size: 16px;
    color: #999;

    @media (max-width: 767px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
`

export const InputBox = styled.div`
`

export const WaitLoader = styled.div`
    width: 100vw;
    height: 100vh;
    cursor: wait;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999;
`

export const TextBox = styled.input.attrs({ type: 'text' })`
    width: 265px;
    border: 1px solid transparent;
    outline: none;
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-radius: 10px;

    &:disabled {
        background-color: transparent;
        padding: 10px 0px;
    }
        
    &:disabled:hover {
        border-color: transparent;
    }

    &:hover {
        border-color: hsl(0, 0%, 70%);
    }

    &:focus {
        border: 1px solid #2684ff;
        box-shadow: 0px 0px 0px 1px #2684ff;
    }

    @media (max-width: 767px) {
        width: 100%;
        font-size: 16px;
    }
`

export const SaveButton = styled.button`
    font-family: 'Gilroy-ExtraBold';
    font-size: 16px;
    font-weight: 400;
    box-sizing: border-box;
    padding: 14px 65px;
    border-radius: 10px;
    background-color: #133b51;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    margin-top: 25px;
    width: fit-content;

    @media (max-width: 767px) {
        width: 100%;
    }
`

export default function MyData ({profile})
{
    const { lang } = useContext(LangContext);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Wrapper>
            {isLoading && <WaitLoader />}
            <Title>
                {Translations[lang].myData}
            </Title>
            <Cards>
                <PersonalData profile={profile} setIsLoading={setIsLoading} />
                <CompanyData profile={profile} setIsLoading={setIsLoading} />
                <Address profile={profile} setIsLoading={setIsLoading} />
                <ContactData profile={profile} setIsLoading={setIsLoading} />
            </Cards>
        </Wrapper>
    )
}