import { useContext } from "react";
import { Link } from "../Abstract/MenuComponents";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { Translations } from "./Lang";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";
import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

const Subtitle = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 20px;
  font-weight: 400;
  color: #ffd600;
  
  @media (max-width: 767px) {
    font-size: 16px;
  }
`

const Title = styled.div`
  margin-top: 9px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
  
  @media (max-width: 767px) {
    margin-top: 4px;
    font-size: 32px;
    width: 221px;
    line-height: 34px;
  }
`

const Descr = styled.div`
  margin-top: 13px;
  font-family: 'Gilroy-Light';
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  
  @media (max-width: 767px) {
    font-size: 16px;
    width: 264px;
  }
`

const MiddleWrapper = styled.div`
  width: 685px;
  padding-bottom: ${params => params.$paddingBottom}px;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
`

export default function CampMenu ({ title, descr, paddingBottom = 66 })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      menuSliderTop={370}
      backgroundImage="/sub/camp/bg.webp"

      navigation={<>
        <Link href="/camp/polkolonie-sportowo-zeglarskie">{Translations[lang].camp.sportSummerCamps}</Link>
        <Link href="/camp/polkolonie-zeglarskie">{Translations[lang].camp.summerCamps}</Link>
        <Link href="/camp/obozy-zeglarskie">{Translations[lang].camp.sailorsCamp}</Link>
        <Link href="/camp/oboz-szkoleniowy">{Translations[lang].camp.tutorCamp}</Link>
      </>}

      middleSection={
        <MiddleWrapper $paddingBottom={paddingBottom}>
          <Subtitle>Camp</Subtitle>
          <Title>{title}</Title>
          <Descr>{descr}</Descr>
        </MiddleWrapper>
      }

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 606 780 197"/>
          <MenuStripeLinkPhone tel="+48 887 234 362"/>
          <MenuStripeLinkMail mail="rezerwacje@forsail.pl" />
          <MenuStripeSocials />
        </>
      }
    />
  )
}