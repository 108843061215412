import { useParams } from "react-router-dom";
import ExtendedPageMenu from "../../Components/Menu/Factory/ExtendedPageMenu";
import Footer from "../../Components/Footer/Footer";
import styled from "styled-components";
import ContactSection from "../../Components/ContactSection/ContactSection";
import { Translations } from "./Lang";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../App";
import axios from "axios";
import __server from "../../Api";

import City from "./City";
import { ScaleLoader } from "react-spinners";

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const BackButton = styled.a`
  margin-top: 30px;
  text-decoration: none;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;

  > img {
    width: 40px;
    margin-right: 14px;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
  }
`

const Title = styled.div`
  margin-top: 48px;
  font-family: 'Gilroy-ExtraBold';
  font-size: 40px;
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    margin-top: 18px;
    font-size: 32px;
    line-height: 35px;
  }
`

const Cities = styled.div`
  margin-top: 39px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px 36px;

  @media (max-width: 767px) {
    margin-top: 18px;
    grid-template-columns: 1fr;
    gap: 21px;
  }
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
`

export default function CountryPage ()
{
  const { lang } = useContext(LangContext);
  const { country } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [countryName, setCountryName] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${__server}/front/city?country=${country}&lang=${lang}`).then((resp) => {
      setList(resp.data.cities);
      setCountryName(resp.data.country[`name${lang.toLocaleUpperCase()}`]);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
    })
  }, [lang]);

  return (
    <>
      <ExtendedPageMenu 
        title={Translations[lang].menu.title} 
        descr={Translations[lang].menu.descr} 
      />
      <Wrapper>
        <BackButton href="/nasze-destynacje">
          <img src="/ico/arrow-back.svg" />
          {Translations[lang].country.backButton}
        </BackButton>
        <Title>
          {countryName}
        </Title>
        <Loading>
          {isLoading && <ScaleLoader color="#133b51" style={{ marginTop: 100 }}/>}
        </Loading>
        <Cities>
          {list.map((city, key) => (
            <City {...city} key={key} country={country} />
          ))}
        </Cities>
      </Wrapper>
      <ContactSection top="75px" />
      <Footer />
    </>
  )
}