import { useContext } from "react";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import styled from "styled-components";
import { Link } from "../Abstract/MenuComponents";

import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

export const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 48px;
  font-weight: 400;
  line-height: 49px;
  color: #fff;
  width: 427px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 285px;
  }
`

export const Descr = styled.div`
  width: 450px;
  margin-top: 12px;
  font-family: 'Gilroy-Light';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  width: 450px;
  @media (max-width: 767px) {
    font-size: 14px;
    margin-top: 4px;
    width: 340px;
  }
`;

export default function ExtendedPageMenu ({ title, descr })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      navigation={<>
        <Link href="/katalog-jachtow">{Translations[lang].home.yachtsCatalogue}</Link>
        <Link href="/nasze-destynacje">{Translations[lang].home.ourDestinations}</Link>
        <Link href="/o-nas">{Translations[lang].home.aboutUs}</Link>
        <Link href="/blog">{Translations[lang].home.blog}</Link>
      </>}

      middleSection={
        <>
          <Title>{title}</Title>
          <Descr>{descr}</Descr>
        </>
      }

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 887 234 362" />
          <MenuStripeLinkMail mail="mailto:biuro@forsail.pl" />
          <MenuStripeSocials />
        </>
      }

    />
  )
}