import { useContext, useEffect, useState } from "react";
import { Translations } from "../Lang";
import { Card, EditToggle, Grid, Heading, InputBox, Subtitle, Label, TextBox, SaveButton } from "./MyData"; 
import { LangContext } from "../../../App";
import axios from "axios";
import __server from "../../../Api";

export default function CompanyData ({ profile, setIsLoading })
{
    const { lang } = useContext(LangContext);

    const [editable, setEditable] = useState(false);

    const [companyInfo1, setCompanyInfo1] = useState("");
    const [companyInfo2, setCompanyInfo2] = useState("");

    useEffect(() => {
        if (profile != null) {
            setCompanyInfo1(profile.companyInfo1);
            setCompanyInfo2(profile.companyInfo2);
        }
    }, [profile])


    const onSave = function (e)
    {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${__server}/front/profile`, {
            companyInfo1: companyInfo1, companyInfo2: companyInfo2
        }).then((resp) => {
        }).catch((err) => {
            // idk
        }).finally(() => {
            // idk
            setEditable(false);
            setIsLoading(false);
        })
    }

    return (
        <Card onSubmit={onSave}>
            <Heading>
                <Subtitle>
                    {Translations[lang].myDataPage.companyData}
                </Subtitle>
                <EditToggle src="/ico/edit.svg" onClick={(e) => setEditable(!editable)}/>
            </Heading>
            <Grid>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.companyName}
                    </Label>
                    <TextBox maxlength={64} disabled={!editable} value={companyInfo1} onChange={(e) => setCompanyInfo1(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.nip}
                    </Label>
                    <TextBox maxlength={14} disabled={!editable} value={companyInfo2} onChange={(e) => setCompanyInfo2(e.target.value)}/>
                </InputBox>
            </Grid>
            {editable && <SaveButton>{Translations[lang].myDataPage.save}</SaveButton>}
        </Card>
    )
}