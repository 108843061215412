export const Translations = {
  pl: {
    directions: {
      title: <>Nasze <span>kierunki</span></>,
      descr: "Luksusowy hotel w urokliwym miejscu z pięknym widokiem na morze i płynący po nim jacht, to niewątpliwy atut wyjazdu incentive. My proponujemy miejsce na tym właśnie jachcie, z którego możesz podziwiać zarówno ten hotel, jak i setki innych, jeszcze piękniejszych miejsc, niedostępnych z lądu. Poprzez SAIL INCENTIVE – żeglarskie imprezy firmowe – odkrywamy nowe lub znane destynacje na nowo!",
      card1: <>Lider w dziedzinie incentive pod żaglami – autorskie programy: <span>Mazury, Bałtyk, Morze Śródziemne, Karaiby, Seszele, Tajlandia</span></>,
      card2: <>Czartery jachtów w kraju i zagranicą – <span>sprawdzeni armatorzy, najlepsze żeglarskie destynacje</span></>
    },
    recomended: {
      title: (
        <>
          Polecane destynacje na <span>Czarter Jachtu</span>
        </>
      ),
      btn: "WSZYSTKIE REJSY",
    },
    seasons: {
      title: (
        <>
          Latem żagle - <span>zimą narty</span>
        </>
      ),
      descr:
        "Półkolonie żeglarskie nad Zalewem Zegrzyńskim oraz półkolonie narciarskie i snowboardowe dla dzieci i młodzieży. Różne kategorie wiekowe. Posiadamy unikatowy program, wykwalifikowaną i doświadczoną kadrę, ponad 16 lat doświadczenia w organizowaniu wypoczynku pod żaglami i na nartach.",
      list: [
        "Półkolonie sportowo-żeglarskie",
        "Kolonie żeglarskie",
        "Obozy żeglarskie",
      ],
      btn: "SPRAWDŹ OFERTĘ",
      btn2: "PRZEJDŹ DO FORSAIL&SKI CLUB"
    },
    faq: {
      title: (
        <>
          Poznaj <span>FAQ</span>
        </>
      ),
      btn: "PRZEJDŹ DO FAQ",
      accordion: [
        {
          title: "Czy rejsy są bezpieczne?",
          descr:
            "Bezpieczeństwo naszych klientów jest dla nas priorytetem. Wszystkie nasze statki spełniają najwyższe standardy bezpieczeństwa, a załoga jest odpowiednio przeszkolona w zakresie procedur awaryjnych. Ponadto, przed każdym rejsem przeprowadzamy szczegółowe instruktaże bezpieczeństwa dla wszystkich uczestników.",
        },
        {
          title:
            "Jakie warunki trzeba spełnić, by zapisać się na kurs żeglarski?",
          descr:
            "Kandydatem do uzyskania tytułu sternika może zostać wyłącznie osoba pełnoletnia. Warunkiem uzyskania stopnia żeglarza jachtowego jest zaliczenie egzaminu. Szczegóły dotyczące naszych kursów możesz znaleźć w sekcji Forsail Academy.",
        },
        {
          title: "Jakie są koszty organizacji rejsu firmowego?",
          descr:
            "Koszty rejsu firmowego zależą od wielu czynników, takich jak długość rejsu, liczba uczestników, wybrane udogodnienia oraz dodatkowe atrakcje. Aby uzyskać dokładną wycenę, prosimy o kontakt z naszym zespołem, który przygotuje ofertę dostosowaną do Twoich potrzeb.",
        },
        {
          title:
            "Od jakiego wieku dzieci mogą uczestniczyć w obozach żeglarskich?",
          descr:
            "Nasze obozy żeglarskie są przeznaczone dla dzieci w wieku od 8 do 16 lat. Programy są dostosowane do różnych grup wiekowych, aby zapewnić odpowiedni poziom trudności i bezpieczeństwa.",
        },
      ],
    },
  },
  en: {
    recomended: {
      title: (
        <>
          Polecane destynacje na <span>Czarter Jachtu</span>
        </>
      ),
      btn: "WSZYSTKIE REJSY",
    },
    companyCruise: {
      title: (
        <>
          Wybierz <span>Rejs Firmowy</span>
        </>
      ),
      descr: (
        <>
          Rejsy firmowe to doskonała forma integracji i motywacji pracowników.
          Elegancja, komfort oraz możliwość odkrywania uroków lądu od zupełnie
          innej, czasem jedynej możliwej strony – od strony wody to nie tylko
          wspaniały relaks ale również doskonała okazja do mniej formalnych, ale
          częstokroć bardziej produktywnych, kameralnych spotkań biznesowych.{" "}
          <br /> <br />
          Proponujemy rejsy luksusowymi jachtami po ciepłych morzach{" "}
          <b>
            Grecji, Chorwacji, Karaibów, Seszeli oraz naszych polskich Mazurach.
          </b>
        </>
      ),
      btn: "Rejsy dla firm",
      cards: [
        {
          title: "Rejs firmowy do polinezji francuskiej",
          date: (
            <>
              TERMIN: <span>Cały rok</span>
            </>
          ),
          time: (
            <>
              CZAS WYJAZDU: <span>7-14 dni</span>
            </>
          ),
        },
        {
          title: "Rejs firmowy do polinezji francuskiej",
          date: (
            <>
              TERMIN: <span>Cały rok</span>
            </>
          ),
          time: (
            <>
              CZAS WYJAZDU: <span>7-14 dni</span>
            </>
          ),
        },
      ],
      checkOut: "Sprawdź",
    },
    seasons: {
      title: (
        <>
          <span>Latem żagle</span> - zimą narty
        </>
      ),
      descr:
        "Półkolonie żeglarskie nad Zalewem Zegrzyńskim oraz półkolonie narciarskie i snowboardowe dla dzieci i młodzieży. Różne kategorie wiekowe. Posiadamy unikatowy program, wykwalifikowaną i doświadczoną kadrę, ponad 16 lat doświadczenia w organizowaniu wypoczynku pod żaglami i na nartach.",
      list: [
        "Półkolonie sportowo-żeglarskie",
        "Kolonie żeglarskie",
        "Obozy żeglarskie",
      ],
      btn: "SPRAWDŹ OFERTĘ",
    },
    faq: {
      title: (
        <>
          Poznaj <span>FAQ</span>
        </>
      ),
      btn: "PRZEJDŹ DO FAQ",
      accordion: [
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
      ],
    },
  },
  de: {
    recomended: {
      title: (
        <>
          Polecane destynacje na <span>Czarter Jachtu</span>
        </>
      ),
      btn: "WSZYSTKIE REJSY",
    },
    companyCruise: {
      title: (
        <>
          Wybierz <span>Rejs Firmowy</span>
        </>
      ),
      descr: (
        <>
          Rejsy firmowe to doskonała forma integracji i motywacji pracowników.
          Elegancja, komfort oraz możliwość odkrywania uroków lądu od zupełnie
          innej, czasem jedynej możliwej strony – od strony wody to nie tylko
          wspaniały relaks ale również doskonała okazja do mniej formalnych, ale
          częstokroć bardziej produktywnych, kameralnych spotkań biznesowych.{" "}
          <br /> <br />
          Proponujemy rejsy luksusowymi jachtami po ciepłych morzach{" "}
          <b>
            Grecji, Chorwacji, Karaibów, Seszeli oraz naszych polskich Mazurach.
          </b>
        </>
      ),
      btn: "Rejsy dla firm",
      cards: [
        {
          title: "Rejs firmowy do polinezji francuskiej",
          date: (
            <>
              TERMIN: <span>Cały rok</span>
            </>
          ),
          time: (
            <>
              CZAS WYJAZDU: <span>7-14 dni</span>
            </>
          ),
        },
        {
          title: "Rejs firmowy do polinezji francuskiej",
          date: (
            <>
              TERMIN: <span>Cały rok</span>
            </>
          ),
          time: (
            <>
              CZAS WYJAZDU: <span>7-14 dni</span>
            </>
          ),
        },
      ],
      checkOut: "Sprawdź",
    },
    seasons: {
      title: (
        <>
          <span>Latem żagle</span> - zimą narty
        </>
      ),
      descr:
        "Półkolonie żeglarskie nad Zalewem Zegrzyńskim oraz półkolonie narciarskie i snowboardowe dla dzieci i młodzieży. Różne kategorie wiekowe. Posiadamy unikatowy program, wykwalifikowaną i doświadczoną kadrę, ponad 16 lat doświadczenia w organizowaniu wypoczynku pod żaglami i na nartach.",
      list: [
        "Półkolonie sportowo-żeglarskie",
        "Kolonie żeglarskie",
        "Obozy żeglarskie",
      ],
      btn: "SPRAWDŹ OFERTĘ",
    },
    faq: {
      title: (
        <>
          Poznaj <span>FAQ</span>
        </>
      ),
      btn: "PRZEJDŹ DO FAQ",
      accordion: [
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Przykładowe tematy szkoleń outdoorowych",
          descr:
            "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        },
      ],
    },
  },
};

Translations.en = Translations.pl;
Translations.de = Translations.pl;
