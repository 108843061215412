import { useContext } from "react";
import { Link, Title, Descr, Subtitle } from "../Abstract/MenuComponents";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";
import MenuSearcher from "../Abstract/MenuSearcher";
import styled from "styled-components";
import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";
import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

const MiddleWrapper = styled.div`
  width: 450px;

  @media (max-width: 767px) {
    width: 340px;
  }
`

export default function YachtCharterMenu ()
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundOverwrite={"/home/bg.webp"}
      navigation={<>
        <Link href="/skipperzy">{Translations[lang].yachtCharter.skippers}</Link>
      </>}

      middleSection={<MiddleWrapper>
        <Subtitle>Jacht Charter</Subtitle>
        <Title>{Translations[lang].yachtCharter.title}</Title>
        <Descr>{Translations[lang].yachtCharter.descr}</Descr>
      </MiddleWrapper>}

      bottomSection={
        <MenuSearcher />
      }

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 725 010 028" />
          <MenuStripeLinkMail mail="czarter@forsail.pl" />
          <MenuStripeSocials />
        </>
      }

      menuSliderTop={370}
    />
  )
}