import { useContext } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"
import { Translations } from "./Lang"
import Skeleton from "react-loading-skeleton"


const Wrapper = styled.div`
  margin-top: 5px;
`

const Title = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 16px;
  font-weight: 400;
  color: #133b51;
  margin-bottom: 20px;

  > span {
    font-family: 'Gilroy-Light';
  }
`

const LocationWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 12px;

  > img {
    width: 29px;
  }
`

const LocationBox = styled.div`
  font-weight: 400;
  color: #133b51;
`

const Label = styled.div`
  font-family: 'Gilroy-ExtraBold';
  font-size: 15px;
`

const Name = styled.div`
  font-family: 'Gilroy-Light';
  font-size: 20px;
`

export default function Top ({ product, startBase, endBase })
{
  const { lang } = useContext(LangContext);


  const Location = function ({ label, location })
  {
    return (
      <LocationWrapper>
        <img src="/ico/pointer.svg" />
        <LocationBox>
          <Label>
            {label}:
          </Label>
          <Name>
            {location}
          </Name>
        </LocationBox>
      </LocationWrapper>
    )
  }

  if (product == null || startBase == null || endBase == null) {
    return <Skeleton width={635} height={200} />
  }
  
  return (
    <Wrapper>
      <Title>
        {Translations[lang].yachtKind}: <span>{product}</span>
      </Title>
      <Location label={Translations[lang].startBase} location={startBase}/>
      <Location label={Translations[lang].endBase} location={endBase}/>
    </Wrapper>
  )
}