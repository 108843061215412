import styled from "styled-components"
import { Translations } from "../Lang"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { LangContext } from "../../../App"
import axios from "axios"
import {__countires_with_bundles, __countries} from "../../../Api"
import __server from "../../../Api"

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import SelectSearch from "../Components/SelectSearch"

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { pl, de, enGB } from 'date-fns/locale';
registerLocale('pl', pl);
registerLocale('de', de);
registerLocale('en', enGB);

const SearchBox = styled.div`
  width: 1260px;
  margin: auto;
  margin-top: 66px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  padding: 20px 32px;
  backdrop-filter: blur(1.5px);
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-top: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
  }
`

const SearchBoxRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InnerSearchBox = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 34px 4px 30px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 7px 30px;
  display: flex;
  align-items: center;
  gap: 13px;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 18px 20px;
    gap: 24px 50px;
    box-shadow: none;

    background-image: url(/menu/bg-search.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`

const SearchButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #32c8d2;
  box-sizing: border-box;
  padding: 21px 45px;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  color: #fff;
`

const SearchProp = styled.div`
  width: 133px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const SearchLabel = styled.div`
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 300;
  color: #878b93;
  text-transform: uppercase;
`

const SearchSelectBox = styled.div`
  display: flex;
`

const SearchPropDivider = styled.div`
  height: 46px;
  width: 1px;
  background-color: #878b93;

  @media (max-width: 767px) {
    display: none;
  }
`

const FormWrapper = styled.form`
  width: 100%;
  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
    margin-bottom: 20px;
  }

  * {
    z-index: 999;
  }

  .react-datepicker__month-container {
    translate: 1px;
    translate: -1px;
  }
` /** niewiem czemó te translaty naprawiają z-index, domyślam się, ale powątpiewam */

const DatePickerStyle = styled.div`
  input
  {
    width: 100%;
    border: none;
    outline: none;
    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
  }
`

const ArbitraryText = styled.div`
  width: 774px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #878b93;
`

const ExtrasList = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 30px;
`

const ExtraOption = styled.div`
  display: flex;
  align-items: center;

  &:hover > span {
    display: block;
  }

  > div {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: #878b93;
  }

  > svg {
    width: 10px;
    height: 10px;
    translate: 2px -5px;
  }

  > span {
    position: absolute; 
    top: 205px;
    width: 155px;
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    padding: 10px;
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    color: #878b93;
    border-radius: 10px;
    display: none;
  }
`

export const SearchFilterContext = createContext();


export default function MenuSearcher ({})
{ 
  const { lang } = useContext(LangContext);
  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  const formRef = useRef();

  const [bundleDescription, setBundleDescription] = useState(<></>);
  const [extras, setExtras] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [filters, setFilters] = useState({
    start: null,
    days: null,
    kind: null,
    country: null,
    heads: null,
    bundle: 'standard',
    cabins: null
  });


  useEffect(() => {
    if (!formRef.current) {
      return () => {};
    }

    if (filters.country == null || filters.kind == null) {
      setBundleDescription("Należy podać kraj oraz rodzaj jachtu");
      return () => {};
    }

    setExtras([]);
    setBundleDescription("Pobieranie danych pakietu...");
    axios.get(`${__server}/front/bundles/${filters.bundle}/${filters.kind}/${filters.country}`).then((resp) => {
      setBundleDescription(resp.data.static.map(n => n.name).join(", "));
      setExtras(resp.data.extras);
    }).catch((err) => {
      setBundleDescription("Nie udało się pobrać danych pakietu");
    });

  }, [filters]);


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newFilters = {...filters};

    for (const key of Object.keys(filters)) {
      if (params.has(key)) {
        newFilters[key] = params.get(key);
      }
    }

    setFilters(newFilters);
    fetchAPICountriesExpectMakeConstantOnesFirst();

  }, []);


  const fetchAPICountriesExpectMakeConstantOnesFirst = function ()
  {
    axios.get(`${__server}/front/pages/countries`).then((resp) => {
      const baseCountries = resp.data.map((code) => countryCodeToOption(code));

      const baseWithoutTop = baseCountries.filter(c => !__countries.includes(c.value));
      const topCountries = __countries.map(c => countryCodeToOption(c));

      const sortedCountries = topCountries.concat(baseWithoutTop);

      setCountryList(sortedCountries);
    }).catch((err) => {
      console.log('[hero menu]', err);
    })
  }

  const countryCodeToOption = function (countryCode)
  {
    return {
      value: countryCode,
      label: countries.getName(countryCode, lang)
    }
  }


  const convertTranslationToOptions = function (obj) 
  {
    return Object.keys(obj).map((key) => {
      return {
        value: key,
        label: obj[key]
      }
    })
  }

  const onFormSubmit = function (e)
  {
    e.preventDefault();

    const filtersWithoutNulls = {};

    for (const key of Object.keys(filters)) {
      if (filters[key] !== null) {
        filtersWithoutNulls[key] = filters[key];
      }
    }

    const start = new Date(filtersWithoutNulls['start']);
    const yyyy = start.getFullYear();
    const mm = String(start.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(start.getDate()).padStart(2, '0');

    filtersWithoutNulls['start'] = `${yyyy}-${mm}-${dd}`;

    const searchParams = new URLSearchParams(filtersWithoutNulls);
    window.location.href = `/oferty?${searchParams.toString()}`;
  }

  const isDateValid = function (date)
  {
    const dateObj = new Date(date);
    return dateObj.getUTCDay() == 5 && dateObj > new Date();
  }

  const getBundles = function ()
  {
    const translatedBundles = __countires_with_bundles.includes(filters.country)
      ? Translations[lang].spBundles
      : Translations[lang].spBundlesStandardOnly;

    return convertTranslationToOptions(translatedBundles);
  }

  return (
    <SearchFilterContext.Provider value={{filters, setFilters}} >
      <FormWrapper onSubmit={onFormSubmit} ref={formRef}>
        <SearchBox>
          <SearchBoxRow>
            <InnerSearchBox>
              <SearchLabel>
                DODATKOWE OPCJE
              </SearchLabel>
              <ExtrasList>
                {extras.map((extra, key) => (
                  <ExtraOption>
                    <div>
                      {extra.name}
                    </div>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="uiw:information-o" clip-path="url(#clip0_1977_4535)">
                    <path id="Vector" d="M4 0C6.2092 0 8 1.7908 8 4C8 6.2092 6.2092 8 4 8C1.7908 8 0 6.2092 0 4C0 1.7908 1.7908 0 4 0ZM4 0.558C3.08712 0.558 2.21164 0.920638 1.56614 1.56614C0.920638 2.21164 0.558 3.08712 0.558 4C0.558 4.91288 0.920638 5.78836 1.56614 6.43386C2.21164 7.07936 3.08712 7.442 4 7.442C4.91288 7.442 5.78836 7.07936 6.43386 6.43386C7.07936 5.78836 7.442 4.91288 7.442 4C7.442 3.08712 7.07936 2.21164 6.43386 1.56614C5.78836 0.920638 4.91288 0.558 4 0.558ZM3.942 2.884C4.096 2.884 4.2208 3.0092 4.2208 3.1632V6.1864C4.21807 6.25857 4.18748 6.32687 4.13545 6.37696C4.08343 6.42705 4.01402 6.45503 3.9418 6.45503C3.86958 6.45503 3.80017 6.42705 3.74815 6.37696C3.69612 6.32687 3.66553 6.25857 3.6628 6.1864V3.1628C3.6628 3.0088 3.7876 2.884 3.942 2.884ZM3.9532 1.7676C4.05186 1.7676 4.14648 1.80679 4.21624 1.87656C4.28601 1.94632 4.3252 2.04094 4.3252 2.1396C4.3252 2.23826 4.28601 2.33288 4.21624 2.40264C4.14648 2.47241 4.05186 2.5116 3.9532 2.5116C3.85454 2.5116 3.75992 2.47241 3.69016 2.40264C3.62039 2.33288 3.5812 2.23826 3.5812 2.1396C3.5812 2.04094 3.62039 1.94632 3.69016 1.87656C3.75992 1.80679 3.85454 1.7676 3.9532 1.7676Z" fill="#B2C6D7"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1977_4535">
                    <rect width="8" height="8" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    <span>
                      {extra.description}
                    </span>
                  </ExtraOption>
                ))}
              </ExtrasList>
            </InnerSearchBox>
          </SearchBoxRow>
          <SearchBoxRow>
            <InnerSearchBox>
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slBundles}
                </SearchLabel>
                <SelectSearch
                  name="bundle"
                  placeholder={Translations[lang].spBundle}
                  isSearchable={false}
                  options={getBundles()}
                  required
                />
              </SearchProp>
              <SearchPropDivider />
              <ArbitraryText>
                {(filters.bundle == "standard" || (filters.country != null && filters.kind != null)) && Translations[lang].bundleDescription[filters.bundle]}
                {bundleDescription}
              </ArbitraryText>
            </InnerSearchBox>
          </SearchBoxRow>
          <SearchBoxRow>
            <InnerSearchBox>
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slCountry}
                </SearchLabel>
                <SelectSearch
                  name="country"
                  placeholder={Translations[lang].spCountry}
                  isSearchable
                  options={countryList}
                />
              </SearchProp>
              {/* ---------------------------------------- */}
              <SearchPropDivider />
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slYachtType}
                </SearchLabel>
                <SelectSearch 
                  name="kind" 
                  placeholder={Translations[lang].spYachtType}
                  isSearchable={false} 
                  options={Translations[lang].spYachtTypes}
                />
              </SearchProp>
              {/* ---------------------------------------- */}
              <SearchPropDivider />
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slDate}
                </SearchLabel>
                <SearchSelectBox>
                  <DatePickerStyle>
                    <DatePicker
                      locale={lang}
                      selected={filters.start}
                      onChange={(date) => setFilters({...filters, start: date})}
                      filterDate={isDateValid}
                      placeholderText="Wybierz datę"
                      required
                      dateFormat="yyyy-MM-dd"
                    />
                  </DatePickerStyle>
                </SearchSelectBox>
              </SearchProp>
              {/* ---------------------------------------- */}
              <SearchPropDivider />
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slDays}
                </SearchLabel>
                <SelectSearch
                  required
                  name="days"
                  placeholder={Translations[lang].spChoose}
                  isSearchable={false}
                  options={convertTranslationToOptions(Translations[lang].spDays)}
                />
              </SearchProp>
              {/* ---------------------------------------- */}
              <SearchPropDivider />
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slCabs}
                </SearchLabel>
                <SelectSearch
                  name="cabins"
                  placeholder={Translations[lang].spChoose}
                  isSearchable={false}
                  options={convertTranslationToOptions(Translations[lang].spCabins)}
                />
              </SearchProp>
              {/* ---------------------------------------- */}
              <SearchPropDivider />
              {/* ---------------------------------------- */}
              <SearchProp>
                <SearchLabel>
                  {Translations[lang].slHeads}
                </SearchLabel>
                <SelectSearch
                  name="heads"
                  placeholder={Translations[lang].spChoose}
                  isSearchable={false}
                  options={convertTranslationToOptions(Translations[lang].spHeads)}
                  required
                />
              </SearchProp>
              {/* ---------------------------------------- */}
            </InnerSearchBox>
            <SearchButton>{Translations[lang].search}</SearchButton>
          </SearchBoxRow>
        </SearchBox>
      </FormWrapper>
    </SearchFilterContext.Provider>
  )
}
