import { useContext, useEffect, useState } from "react";
import { Translations } from "../Lang";
import { Card, EditToggle, Grid, Heading, InputBox, Subtitle, Label, TextBox, SaveButton } from "./MyData"; 
import { LangContext } from "../../../App";
import axios from "axios";
import __server from "../../../Api";

export default function ContactData ({ profile, setIsLoading })
{
    const { lang } = useContext(LangContext);

    const [editable, setEditable] = useState(false);

    const [mail, setMail] = useState("");
    const [tel, setTel] = useState("");

    useEffect(() => {
        if (profile != null) {
            setMail(profile.mail);
            setTel(profile.tel);
        }
    }, [profile])


    const onSave = function (e)
    {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${__server}/front/profile`, {
            tel: tel
        }).then((resp) => {
        }).catch((err) => {
            // idk
        }).finally(() => {
            // idk
            setEditable(false);
            setIsLoading(false);
        })
    }

    return (
        <Card onSubmit={onSave}>
            <Heading>
                <Subtitle>
                    {Translations[lang].myDataPage.contactData}
                </Subtitle>
                <EditToggle src="/ico/edit.svg" onClick={(e) => setEditable(!editable)}/>
            </Heading>
            <Grid>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.mail}
                    </Label>
                    <TextBox disabled={true} value={mail}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.tel}
                    </Label>
                    <TextBox maxlength={12} disabled={!editable} value={tel} onChange={(e) => setTel(e.target.value)}/>
                </InputBox>
            </Grid>
            {editable && <SaveButton>{Translations[lang].myDataPage.save}</SaveButton>}
        </Card>
    )
}