import { useContext, useEffect, useState } from "react";
import { Translations } from "../Lang";
import { Card, EditToggle, Grid, Heading, InputBox, Subtitle, Label, TextBox, SaveButton } from "./MyData"; 
import { LangContext } from "../../../App";
import axios from "axios";
import __server from "../../../Api";

export default function PersonalData ({ profile, setIsLoading })
{
    const { lang } = useContext(LangContext);

    const [editable, setEditable] = useState(false);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [serial, setSerial] = useState("");

    useEffect(() => {
        if (profile != null) {
            setName(profile.name);
            setSurname(profile.surname);
            setSerial(profile.serial);
        }
    }, [profile])


    const onSave = function (e)
    {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${__server}/front/profile`, {
            name: name, surname: surname, serial: serial
        }).then((resp) => {
        }).catch((err) => {
            // idk
        }).finally(() => {
            // idk
            setEditable(false);
            setIsLoading(false);
        })
    }

    return (
        <Card onSubmit={onSave}>
            <Heading>
                <Subtitle>
                    {Translations[lang].myDataPage.personalData}
                </Subtitle>
                <EditToggle src="/ico/edit.svg" onClick={(e) => setEditable(!editable)}/>
            </Heading>
            <Grid>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.name}
                    </Label>
                    <TextBox required={true} maxlength={32} disabled={!editable} value={name} onChange={(e) => setName(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.surname}
                    </Label>
                    <TextBox required={true} maxlength={32} disabled={!editable} value={surname} onChange={(e) => setSurname(e.target.value)}/>
                </InputBox>
                <InputBox>
                    <Label>
                        {Translations[lang].myDataPage.serial}
                    </Label>
                    <TextBox required={true} maxlength={9} disabled={!editable} value={serial} onChange={(e) => setSerial(e.target.value)}/>
                </InputBox>
            </Grid>
            {editable && <SaveButton>{Translations[lang].myDataPage.save}</SaveButton>}
        </Card>
    )
}