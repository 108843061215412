import { useContext, useEffect, useState } from "react"
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import styled from "styled-components";



const Wrapper = styled.div`
    margin-top: 20px;
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
    font-size: 20px;
    color: #133b51;

    display: flex;
    align-items: center;
    gap: 25px;
`

const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    translate: 0px -2px;
`

const Label = styled.label`
    font-family: 'Gilroy-Light';
`

const Checkbox = styled.input`
    accent-color: #32c8d2;
`

export default function BundleExtras ({ basePrice, extras, setPrice, extrasToggled, setExtrasToggled })
{
    const { lang } = useContext(LangContext);


    useEffect(() => {
        setPrice(basePrice + extrasToggled.reduce((a, b) => (b.toggled ? b.value : 0) + a, 0));
    }, [basePrice, extrasToggled]);

    const ExtraOption = function ({ idx, name, value })
    {
        return (
            <Box>
                <Checkbox 
                    type="checkbox" 
                    checked={extrasToggled[idx].toggled} 
                    onChange={(e) => {
                        const newToggled = [...extrasToggled];
                        console.log(extrasToggled);
                        newToggled[idx].toggled = !newToggled[idx].toggled;
                        setExtrasToggled(newToggled);
                    }} 
                    id={`cbx-${idx}`}
                />
                <Label htmlFor={`cbx-${idx}`}>
                    {name} ({parseInt(value)} EUR)
                </Label>
            </Box>
        )
    }

    return (
        <Wrapper>
            {Translations[lang].extras}
            {extrasToggled.map((extra, key) => (
                <ExtraOption {...extra} key={key} idx={key} />
            ))}
        </Wrapper>
    )
}