import { useContext, useEffect, useState } from "react";
import PageMenu from "../../Components/Menu/Factory/PageMenu";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import ExtendedPageMenu from "../../Components/Menu/Factory/ExtendedPageMenu";
import styled from "styled-components";
import Footer from "../../Components/Footer/Footer";
import ContactSection from "../../Components/ContactSection/ContactSection";
import axios from "axios";
import __server from "../../Api";
import Country from "./Country";
import { ScaleLoader } from "react-spinners";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1320px;
  margin: auto;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Title = styled.div`
  margin-top: 31px;
  font-family: 'Gilroy-Light';
  font-size: 40px;
  font-weight: 400;
  color: #133b51;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 32px;
  }
`

const Descr = styled.div`
  margin-top: 25px;
  text-align: center;
  width: 953px;
  font-family: 'Gilroy-Light';
  font-size: 20px;
  font-weight: 400;
  color: #133b51;
  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Countries = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 25px;
`

const Loading = styled.div`
  display: flex;
  justify-content: center;
`

export default function OurDestinationsPage ()
{
  const { lang } = useContext(LangContext);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${__server}/front/country`).then((resp) => {
      console.log(resp.data);
      setList(resp.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
    })
  }, []);

  return (
    <>
      <ExtendedPageMenu 
        title={Translations[lang].menu.title} 
        descr={Translations[lang].menu.descr} 
      />
      <Wrapper>
        <Title>
          {Translations[lang].top.title}
        </Title>
        <Descr>
          {Translations[lang].top.descr}
        </Descr>
        <Loading>
          {isLoading && <ScaleLoader color="#133b51" style={{ marginTop: 100 }}/>}
        </Loading>
        <Countries>
          {list.map((country, key) => (
            <Country {...country} name={country[`name${lang.toLocaleUpperCase()}`]} key={key} />
          ))}
        </Countries>
      </Wrapper>
      <ContactSection top="76px" />
      <Footer />
    </>
  )
}