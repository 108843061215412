
export const Translations = {
  pl: {
    cataloguePrice: "Cena katalogowa",
    yachtKind: "Rodzaj jachtu",
    startBase: "Baza startowa",
    endBase: "Baza końcowa",
    obligatoryPayment: "Opłaty obowiązkowe",
    yachtPrice: "Cena jachtu",
    charterPrice: "Pakiet czarterowy",
    sudoPayments: "Opłaty administracyjne",
    securityDeposit: "Kaucja",
    commission: "Prowizja",
    dateFrom: "Data od",
    dateTo: "Data do",
    discount: "Rabat",
    price: "Nasza cena",
    bookingButton: "Zarezerwuj",
    selectedBundle: "WYBRANY PAKIET:",
    bundle: "Pakiet",
    includes: "zawiera",
    extras: "Opcje dodatkowe:",
    heads: "Ilość osób: ",
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;