import styled from "styled-components"
import Section from "./Section"
import BundleSection from "./BundleSection"
import { useEffect, useState } from "react"

const Wrapper = styled.div`
`

export default function BodyForYacht ({ data })
{
    const [price, setPrice] = useState(null);

    const [ extrasToggled, setExtrasToggled ] = useState([]);


    useEffect(() => {
        if (data != null && data.bundles != null) {
            setPrice(data.bundles.price);
            setExtrasToggled(
                data.bundles.extras.map((extra) => {
                    return {
                        ...extra,
                        toggled: false,
                        value: parseFloat(extra.value)
                    }
                })
            );
        }
    }, [data]);


    return (
        <Wrapper>
            <Section {...data} bundledPrice={price} extrasToggled={extrasToggled} />
            <BundleSection {...data?.bundles} setPrice={setPrice} setExtrasToggled={setExtrasToggled} extrasToggled={extrasToggled} />
        </Wrapper>
    )
}